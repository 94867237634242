$(document).ready(function () {
    // Init Wow
    wow = new WOW({
        animateClass: 'animated',
        offset: 100
    });
    wow.init();

    function enableSubmit() {
        $('#form-submit').prop('disabled', false);
    };

    function disableSubmit() {
        $('#form-submit').prop('disabled', true);
    }

    // Navigation scrolls
    $('.navbar-nav li a').on('click', function (event) {
        $('.navbar-nav li').removeClass('active');
        $(this).closest('li').addClass('active');
        var $anchor = $(this);
        var nav = $($anchor.attr('href'));
        if (nav.length) {
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top
            }, 1500, 'easeInOutExpo');

            event.preventDefault();
        }
    });

    // About section scroll
    $(".overlay-detail a").on('click', function (event) {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 900, function () {
            window.location.hash = hash;
        });
    });

    //jQuery to collapse the navbar on scroll
    $(window).scroll(function () {
        if ($(".navbar-default").offset().top > 50) {
            $(".navbar-fixed-top").addClass("top-nav-collapse");
        } else {
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
        }
    });

    // Contact form
    $('#contact-form').submit(function (e) {
        e.preventDefault();
        var params = {
            name: $("#name").val(),
            address: $("#address").val(),
            email: $("#email").val(),
            subject: $("#subject").val(),
            message: $("#message").val()
            // g_response: grecaptcha.getResponse()
        };
        console.log(params);


        if (!validateForm(params)) {
            alert("I dati forniti sono incompleti o non corretti!");
            return false;
        }

        $.ajax({
            url: "mailer.php",
            data: params,
            type: "POST",
            dataType: "json",
            success: function (response) {
                if (typeof response === 'undefined') {
                    console.log("Unknoun error:", response);
                    return;
                }
                // Empty form fields.
                $("#name, #email, #subject").val('');
                $("#message").val(response.message);
                $('#form-submit').remove();
            },
            error: function (error) {
                console.log("Error sending email", error);
            }
        });
    });

    // Testimonials Slider
    $('.bxslider').bxSlider({
        adaptiveHeight: true,
        mode: 'fade'
    });

    /**
     * Validate form data before submit.
     *
     * @param {obj} params
     * @return {boolean}
     */
    function validateForm(params) {
        // Validate mandatory fields
        return (
            params.name.length === 0 ||
            params.email.length === 0 ||
            params.subject.length === 0 ||
            params.message.length === 0 ||
            params.address.length !== 0
            // || params.g_response.length === 0
        ) ? false : true;
    }
});
